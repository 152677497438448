@import '~font-awesome/css/font-awesome.min.css';

html body {
  /* font-family: 'Open Sans', sans-serif !important; */
  font-family: 'Oswald', sans-serif !important;
  font-size: 13px !important;
}

@font-face {
  font-family: 'Conv_Montserrat-Light';
  src: url('./fonts/Montserrat-Light.eot');
  src: local('☺'), url('./fonts/Montserrat-Light.woff') format('woff'),
    url('./fonts/Montserrat-Light.ttf') format('truetype'),
    url('./fonts/Montserrat-Light.svg') format('svg');
  font-weight: normal;
  font-style: normal;
}


.ag-theme-alpine .ag-header-cell,
.ag-theme-alpine .ag-header-group-cell,
.ag-theme-alpine .ag-cell {
  font-family: 'Oswald', sans-serif !important;
  font-size: 13px !important;
}

input,
button,
select,
optgroup,
textarea {
  font-family: 'Oswald', sans-serif !important;
  font-size: 13px !important;
}

.scrollbar-container.sidebar-nav.ps.ps-container ul li a i {
  background: #6d2a5f;
  padding: 4px;
  display: inline-table;
  color: #fff;
  font-size: 16px;
  border-radius: 4px;
  font-weight: bold;
}

.scrollbar-container.sidebar-nav.ps.ps-container ul li:nth-child(2) a i {
  background: #6d2a5f;
}

.scrollbar-container.sidebar-nav.ps.ps-container ul li:nth-child(3) a i {
  background: #6d2a5f;
}

.scrollbar-container.sidebar-nav.ps.ps-container ul li:nth-child(4) a i {
  background: #997fb8;
}

.scrollbar-container.sidebar-nav.ps.ps-container ul li:nth-child(4) a i {
  background: #399bff;
}

.scrollbar-container.sidebar-nav.ps.ps-container ul li:nth-child(5) a i {
  background: #c78568;
}

.scrollbar-container.sidebar-nav.ps.ps-container ul li:nth-child(6) a i {
  background: #f39c12;
}

.scrollbar-container.sidebar-nav.ps.ps-container ul li:nth-child(7) a i {
  background: #6d2a5f;
}

.scrollbar-container.sidebar-nav.ps.ps-container ul li:nth-child(8) a i {
  background: #6cae67;
}

.sidebar .nav-link {
  padding: 12px 1rem !important;
}

.sidebar {
  background: #6d2a5f !important;
}

.sidebar .nav-link:hover {
  background: #6d2a5f !important
}

.breadcrumb li {
  font-size: 17px;
  font-weight: 300;
  color: #999 !important;
}

.header-fixed.sidebar-fixed.sidebar-lg-show.sidebar-minimized.brand-minimized .scrollbar-container.sidebar-nav ul li a {
  padding: 10px 0 !important;
}

.sidebar-minimized .sidebar .nav-link .nav-icon {
  width: 32px !important;
  font-size: 14px !important;
  background: #a3c900;
  color: #fff !important;
  padding: 9px 7px;
  margin: 0 9px;
  border-radius: 3px;
}

.card-footer a .btn.btn-danger.btn-sm {
  margin-left: 15px;
  padding: 7px 15px;
  font-size: 15px;
  text-transform: uppercase;
  font-weight: 600;
}

.card-body button {
  font-weight: 600;
  text-transform: uppercase;
  font-size: 12px;
  padding: 5px 8px;
}

.userTableList button {
  margin: 0 5px 3px 0px !important;
}

.sidebar-minimized .sidebar ul li:nth-child(2) .nav-link .nav-icon {
  background: #6d2a5f !important;
}

.sidebar-minimized .sidebar ul li:nth-child(3) .nav-link .nav-icon {
  background: #d79425 !important;
}

.sidebar-minimized .sidebar ul li:nth-child(4) .nav-link .nav-icon {
  background: #997fb8 !important;
}

.sidebar-minimized .sidebar ul li:nth-child(5) .nav-link .nav-icon {
  background: #399bff !important;
}

.sidebar-minimized .sidebar ul li:nth-child(6) .nav-link .nav-icon {
  background: #c78568 !important;
}

.sidebar-minimized .sidebar ul li:nth-child(7) .nav-link .nav-icon {
  background: #f39c12 !important;
}

.sidebar-minimized .sidebar ul li:nth-child(8) .nav-link .nav-icon {
  background: #6cae67 !important;
}

.sidebar .nav-link {
  color: rgba(255, 255, 255, 0.6) !important;
  padding: 9px 10px !important;
}

.table.table-hover tr th {
  background: #fff !important;
  border: 0px !important;
  text-transform: uppercase;
  font-size: 14px;
  font-weight: 600;
}

.dropdown-menu li.dropdown-item {
  position: relative;
  padding: 6px 10px;
  border-bottom: 1px solid #c8ced3;
}

.card-body a button.btn.btn-primary.btn-sm,
.add-button-div button.btn {
  text-transform: uppercase;
  font-weight: 600;
  color: #fff;
  background: #6d2a5f;
  border: 1px solid #6d2a5f;
  box-shadow: none !important;
}

ul.galleryItems {
  list-style: none;
  padding: 0px;
}

.table th,
.table td {
  padding: 10px 10px !important;
}

.card-footer button.btn.btn-primary.btn-sm {
  font-size: 14px;
  padding: 7px 15px;
  text-transform: uppercase;
  font-weight: 600;
}

.card-footer button.btn.btn-danger.btn-sm {
  font-size: 14px;
  padding: 7px 15px;
  text-transform: uppercase;
  font-weight: 600;
  margin-right: 10px;
}

.modalFooter .canclebtn {
  margin-left: 0px;
}

.btn-info,
.btn-primary {
  background: #6d2a5f !important;
  border: 1px solid #6d2a5f !important;
  color: #fff !important;
}

.btn-info:hover,
.btn-primary:hover {
  background: transparent !important;
  color: #6d2a5f !important;
}

.btn-danger {
  background: #435568 !important;
  border: 1px solid #435568 !important;
  color: #fff !important;
}

.loginbtn {
  font-size: 16px;
  width: 100px;
  line-height: 26px;
  padding: 4px 5px 6px;
  border-radius: 5px;
}

.login_body::after {
  content: '';
  position: absolute;
  background: #ffffff;
  width: 100%;
  height: 100%;
  opacity: 0.9;
}

.ag-theme-alpine .ag-ltr .ag-has-focus .ag-cell-focus:not(.ag-cell-range-selected) {
  border-color: transparent !important;
}

.login_body .container {
  position: relative;
  z-index: 10;
}

.btn-danger:hover {
  background: transparent !important;
  color: #435568 !important;
}

.btn-success {
  background: #231f20 !important;
  color: #fff !important;
  border: 1px solid #231f20 !important;
}

.btn-success:hover {
  background: transparent !important;
  color: #231f20 !important;
}

.react-bootstrap-table table {
  border: 1px solid #ddd;
}

.checkbox.form-check select.form-control {
  width: 100%;
  float: left;
  margin-bottom: 15px;
}

.checkbox.form-check input.form-control {
  width: 100%;
  float: left;
}

.checkbox.form-check {
  width: 160px;
  float: left;
  margin-left: 5px;
  padding-left: 0;
}

.card-body div .checkbox.form-check:last-child {
  margin-left: 28px;
  height: 50px;
  padding-top: 5px;
}

.btn.btn-success.mysucess {
  position: relative;
  float: left;
  font-size: 11px;
  margin-top: -8px !important;
}

.shownew {
  padding: 0 !important;
  color: #f86c6b !important;
  background: transparent !important;
  border: none !important;
  font-size: 10px !important;
  /* width: 30px;
  height: 30px; */
  text-align: center;
  /* line-height: 10px; */
}

.shownew.actives {
  color: #3ea662 !important;
  border: 2px solid #3ea662;
  border: 2px solid #f86c6b;
}

.shownew.blocked {
  color: #000015 !important;
  border: 2px solid #3ea662;
  border: 2px solid #f86c6b;
}

.iSpan {
  border-radius: 50% !important;
  border: 2px solid #f86c6b;
}

.shownew.actives .iSpan {
  color: #3ea662 !important;
  border: 2px solid #3ea662;
  width: 19px;
  display: inline-block;
  height: 19px;
  margin-left: 3px;
}

.shownew.blocked .iSpan {
  color: #000015 !important;
  border: 2px solid #000015;
  width: 19px;
  display: inline-block;
  height: 19px;
  margin-left: 3px;
}

.warning-featured {
  padding: 0 !important;
  color: #d79425 !important;
  background: transparent !important;
  border-radius: 50% !important;
  border: none !important;
  width: 10px;
  height: 10px;
}

span.iSpan {
  width: 19px;
  height: 19px;
  display: inline-block;
  margin-left: 5px;
  line-height: 14px;
}

.featured-active {
  padding: 0 !important;
  color: #3ea662 !important;
  background: transparent !important;
  border-radius: 50% !important;
  border: 2px solid #3ea662 !important;
  width: 23px !important;
  height: 23px !important;
  display: inline-block;
  text-align: center;
}

/* .btn.btn-info.btn-sm i {
  color: #fff;
} */
.nav-dropdown-items li a.nav-link.active {
  background: #536568 !important;
}

.reset-btn {
  font-size: 10px !important;
  padding: 5px !important;
}

button.mr-05.reset-btn.btn.btn-danger.btn-sm {
  position: relative;
  width: 96px;
  right: 0;
  float: right;
  margin-top: 0;
  padding: 8px !important;
}

.reset_bt {
  float: right;
  width: 100%;
  margin-top: 7px;
}

.specify-width {
  width: 82% !important;
}

#pageDropDown {
  padding: 6px 10px;
  margin-top: -8px !important;
}

.featured-active-main {
  color: #3ea662 !important;
  text-align: center;
}

.warning-featured-main {
  /* color: #d79425 !important; */
  text-transform: none !important;
  background: transparent !important;
  border: none !important;
}

.h-w-l {
  width: 85px !important;
  float: left;
  margin-right: 5px;
}

.modalFooter img {
  display: inline-block;
  float: left;
  width: 40px !important;
  margin-top: -6px !important;
  margin-right: 15px;
}

.action img {
  display: inline-block;
  float: left;
  width: 40px !important;
  /* margin-top: -6px !important; */
  margin-right: 15px;
}

.apply-coupon img {
  display: inline-block;
  float: none !important;
  width: 30px !important;
  margin-left: 10px !important;
}


/* Basic Info Tab Css Start */
.Justify-flex-end {
  display: flex;
  justify-content: flex-end;
}

/* Basic Info Tab Css End */
.preview-img {
  height: 70px !important;
  width: 70px;
  object-fit: contain;
}

.card-body .btn-group-sm.dropdown {
  margin-top: -9px;
}

.card-body .btn-group-sm.dropdown button.dropdown-toggle.btn.btn-secondary {
  padding: 5px;
}

.checkbox.form-check.attribute-body {
  margin: 0px auto 25px !important;
  padding-top: 0 !important;
  width: 100% !important;
}

.checkbox.attribute-body.form-check select.form-select {
  margin-bottom: 10px;
}

label.mainlabel.form-label {
  font-weight: 600;
  font-size: 16px;
  display: block;
}

.fitting-outer .checkbox.attribute-body.form-check {
  height: initial !important;
}

strong.coupon-display-invalid {
  color: red;
}

strong.coupon-display {
  color: green;
}

span.coupon-display {
  color: green;
  font-weight: bold;
}

input#hasNoneAttribute {
  margin-left: 0px !important;
}

label.attribute-label.form-check-label {
  margin-left: 20px !important;
}

.attribute-options img {
  width: 80px !important;
}

.attribute-title {
  font-weight: bold;
  margin-right: 5px;
}

.option-ptag {
  margin: 0 !important;
  padding-left: 50px;
  line-height: 10px;
}

.fitting {
  margin-bottom: 30px;
  padding-left: 0px;
}

.single-fitting {
  margin-bottom: 5px;
  padding-left: 20px;
}

.label-tag {
  font-weight: bold;
  margin-right: 5px;
}

.label-tag-header {
  font-weight: bold;
  margin-right: 5px;
  font-size: 20px;
}

.data-ptag {
  margin: 0 !important;
  padding-left: 0px;
  line-height: 20px;
}

.address_box .data-ptag {
  display: flex;
}

.footer-tag {
  font-size: 15px;
  margin-bottom: 0px;
}

.data-div,
.no-data-div {
  margin-top: 20px;
}

.no-data-div {
  text-align: center;
}

.pac-container {
  z-index: 1051 !important;
}

.radio-inline {
  padding-right: 10px;
}


.modal-dialog.variation-modal {
  max-width: 1096px !important;
  margin: 1.75rem auto;
}

.check-square-position {
  position: absolute;
  font-size: 2rem !important;
}

.app-body {
  display: flex;
  width: 100%;
}

main.main {
  width: 100%;
  display: flex;
  position: relative;
}

button.btn.togglebars {
  position: absolute;
  top: 4px;
  left: 262px;
  border: 1px solid #3d464d;
  transition: 0.5s;
  padding: 0px;
  z-index: 10;
}

ol.breadcrumb {
  padding-left: 69px !important;
}

main.main .rightpanel {
  width: 100%;
  background: #e4e5e6;
  /* min-height: calc(100vh - 112px); */
}

button.btn.togglebars:hover path {
  fill: #fff;
}

button.btn.togglebars:hover {
  background: #3d464d;
}

button.btn.togglebars svg {
  width: 40px;
  height: 40px;
  padding: 10px;
}

.rightpanel .tab-content {
  background: #fff;
  padding: 15px;
  border: 1px solid #c8ced3;
  border-top: 0px;
}

.ag-theme-alpine {
  width: 100% !important;
}

ol.breadcrumb {
  background: #fff;
}

.rightpanel .tab-content {
  background: #fff;
}

.breadcrumb {
  position: relative;
  border-radius: 0;
  border-bottom: 1px solid #c8ced3;
  padding: 0.75rem 1rem !important;
}

.breadcrumb li {
  font-size: 17px;
  font-weight: 300;
  color: #999 !important;
}

.breadcrumb li a {
  color: #6d2a5f;
  text-decoration: none;
}

/* Admin panel css  11/05/2022*/

body {
  font-family: 'Lato', sans-serif !important;
}

/* input,
button,
select,
optgroup,
textarea {
  font-family: 'Lato', sans-serif !important;
} */
.breadcrumb-item+.breadcrumb-item {
  font-weight: 400;
}

.alldetailsuser h4 {
  font-size: 32px;
  font-weight: 600;
  color: #6d2a5f;
}

.alldetailsuser h5 {
  margin-bottom: 15px;
  font-size: 18px;
}

span.navbar-brand {
  margin: 0px;
  width: 250px;
  text-align: center;
}

.login_body.btn-primary {
  color: #fff;
  background-color: #20a8d8 !important;
  border-color: #20a8d8 !important;
}

.login_body {
  height: 100vh;
  position: relative;
  background-image: url(../assets/img/TrajectorySeriesGroup-KINET.jpg);
  background-size: cover;
  position: relative;
  background-position: center;
}

.login_body .form-group {
  margin-bottom: 15px;
  margin-left: 0px;
}

.opensidebar .sidebar {
  width: 0px;
  display: none;
}

.sidebar.hide {
  margin: 0px !important;
}

.admin-panel .nav-tabs .nav-link {
  color: #000;
  cursor: pointer;
}

.rightpanel .card {
  margin-bottom: 15px;
}

.footer a {
  color: #6d2a5f;
  text-decoration: none;
}

.sidebar {
  min-height: calc(100vh - 112px);
}

.modalFooter button {
  margin-left: 15px;
}

.admin-panel .sidebar .nav-link svg {
  width: 40px;
  font-size: 18px;
  text-align: left;
}

.breadcrumb li a,
.breadcrumb li {
  font-size: 16px;
}

.login_body {
  display: flex;
  align-items: center;
  justify-content: center;
}

.form-control,
.form-select {
  box-shadow: none !important;
  border: 1px solid #ccc !important;
  font-size: 14px !important;
}

button {
  box-shadow: none !important;
}

.btn-secondary {
  font-size: 13px !important;
}

/* .logo-resizer span.navbar-brand {
  opacity: 0;
} */

.logo-resizer button.btn.togglebars {
  left: 15px;
}

.sidebar-nav .nav-group-toggle {
  padding: 18px 10px 18px 60px !important;
}

.sidebar-nav .nav-group-items .nav-link {
  padding: 9px 20px !important;
}

a.nav-link.nav-group-toggle {
  position: relative;
}

li.nav-group.Dealers a.nav-link.nav-group-toggle::before {
  content: '';
  width: 25px;
  height: 30px;
  background-image: url(assets/cssImages/verifide.svg);
  position: absolute;
  z-index: 10;
  left: 29px;
  background-repeat: no-repeat;
  background-position: left center;
}

li.nav-group.Products a.nav-link.nav-group-toggle::before {
  content: '';
  width: 24px;
  height: 30px;
  background-image: url(assets/cssImages/product.svg);
  position: absolute;
  z-index: 10;
  left: 26px;
  background-repeat: no-repeat;
  background-position: left center;
}

li.nav-group.Orders a.nav-link.nav-group-toggle::before {
  content: '';
  width: 23px;
  height: 30px;
  background-image: url(assets/cssImages/order.svg);
  position: absolute;
  z-index: 10;
  left: 27px;
  background-repeat: no-repeat;
  background-position: left center;
}

li.nav-group.Gallery a.nav-link.nav-group-toggle::before {
  content: '';
  width: 23px;
  height: 30px;
  background-image: url(assets/cssImages/gallery.svg);
  position: absolute;
  z-index: 10;
  left: 27px;
  background-repeat: no-repeat;
  background-position: left center;
}

li.nav-group.CMS a.nav-link.nav-group-toggle::before {
  content: '';
  width: 22px;
  height: 30px;
  background-image: url(assets/cssImages/cms.svg);
  position: absolute;
  z-index: 10;
  left: 29px;
  background-repeat: no-repeat;
  background-position: left center;
}

li.nav-group.Notifications a.nav-link.nav-group-toggle::before {
  content: '';
  width: 22px;
  height: 30px;
  background-image: url(assets/cssImages/notification.svg);
  position: absolute;
  z-index: 10;
  left: 29px;
  background-repeat: no-repeat;
  background-position: left center;
}

Notifications .PhoneInput {
  border: 1px solid #ced4da;
  height: 48px;
  padding: 5px;
  box-sizing: border-box;
}

.PhoneInput input {
  border: 0px;
  outline: none;
}



.details-view-outer {
  background: #f5f5f5;
  padding: 15px;
  margin: 0px 0px 20px;
  border-radius: 5px;
}

.details-view-outer strong {
  margin-bottom: 15px;
}

.details-view-outer input {
  margin-bottom: 10px;
}

.inner-content-flex {
  display: flex;
  justify-content: space-between;
}

.inner-content-flex .mb-3 {
  width: calc(100% / 4 - 15px);
}

.shipping-flex {
  display: flex;
  flex-direction: row;
  flex-wrap: wrap;
}

.shipping-flex label.form-label {
  width: 100%;
}

.shipping-flex .mb-3 {
  width: calc(100% / 4);
  margin-bottom: 10px !important;
}

.shipping-flex .mb-3 .radio input {
  margin-right: 5px;
  width: 14px;
  height: 13px;
}

.heading-flex {
  display: flex;
  justify-content: space-between;
}

.cardheadertab div {
  display: flex;
  justify-content: space-between;
}

span.closetab {
  background: #000;
  width: 20px;
  display: flex;
  align-items: center;
  justify-content: center;
  color: #fff;
  border-radius: 2px;
  cursor: pointer;
}

.specItem+.specItem::before {
  display: inline-block;
  white-space: pre;
  content: ", ";
}

.variation_popup_error {
  margin-left: 10px !important;
}

.address_box .data-ptag label.label-tag.form-label {
  width: 100px;
}

#Layer_1 path {
  fill: #fff;
}

button:hover #Layer_1 path {
  fill: #74005f;
}

.admin-panel .userTableList button.btn.btn-info.btn-sm svg#Layer_1 path {
  fill: #74005f;
}

.admin-panel .userTableList button.btn.btn-info.btn-sm:hover svg#Layer_1 path {
  fill: #fff;
}

@media (max-width: 767.98px) {
  button.btn.togglebars {
    left: 15px !important;
  }

  span.navbar-brand {
    text-align: left;
  }

  .sidebar {
    position: fixed;
    top: 0;
    bottom: 0;
    z-index: 1000;
    width: 200px;
    left: 0px !important;
    margin-left: -500px !important;
    display: block;
  }

  .opensidebar .sidebar {
    margin: 0px !important;
    width: 200px !important;
    display: block !important;
  }

  .opensidebar .sidebar+span.overlap {
    position: fixed;
    top: 0px;
    bottom: 0px;
    left: 0px;
    right: 0px;
    background: #000;
    z-index: 10;
    opacity: 0.2;
  }

  .logo-resizer button.btn.togglebars,
  button.btn.togglebars {
    left: 15px;
  }
}


.tabletopsec {
  width: 100%;
  display: flex;
  justify-content: space-between;
  margin-bottom: 10px;
}

.rightpaneltop {
  display: flex;
  min-width: auto;
  justify-content: space-between;
  align-items: center;
  white-space: nowrap;
}

.rightpaneltop .form-select.order-type {
  width: 150px;
}

button.build-shop-export.btn.btn-primary.btn-sm {
  margin-bottom: 0px !important;
}

.rightpaneltop .form-select {
  max-width: 100px;
  width: auto;
  margin-left: 10px;
}

.ag-header-cell-filtered {
  background-color: #1b6d85 !important;
  color: #fff !important;
}

.ag-header-cell-filtered span {
  color: #fff !important;
}




.primary-Outer {
  display: flex;
  flex-direction: column;
  height: initial !important;
  line-height: 16px;
  justify-content: initial;
  overflow: hidden;
  padding: 4px 0px;
}

.Address-outline {
  border: 1px solid #ccc;
  border-radius: 5px;
  margin-bottom: 15px;
  overflow: hidden;
}

.Address-outline:last-child {
  border-bottom: 0px;
}

.Address-outline .mb-3 {
  margin: 0px !important;
  padding: 0px;
  border-bottom: 1px solid #ccc;
  position: relative;
}

.Address-outline .mb-3 input {
  position: absolute;
  width: 100%;
  height: 100%;
  left: 0px;
  right: 0px;
  top: 0px;
  bottom: 0px;
  opacity: 0;
}

.Address-outline .mb-3 input:checked+label {
  background: white;
}

.Address-outline .radio.d-flex {
  width: 100%;
}

.Address-outline .radio.d-flex .form-label {
  width: 100%;
  padding: 10px;
  margin: 0px;
}

.Address-outline p {
  margin-bottom: 8px;
}

.Address-outline p:last-child {
  margin: 0px;
}

.address .form-label #new_shipping_address {
  margin-right: 6px;
  width: 20px;
  height: 20px;
  position: relative;
  top: -2px;
}

.modal .modal-body+.btn.btn-primary.btn-sm {
  margin: 0px 15px 15px 15px;
}

.modal-header+.form-group .radio-inline,
.modal-header+.form-group+.form-group .radio-inline {
  display: flex;
  align-items: center;
  margin: 10px 0px;
}

.conditional-checkbox .input-group~div:last-child {
  margin-bottom: 0px;
}

.modal-header+.form-group .radio-inline input,
.modal-header+.form-group+.form-group .radio-inline input,
.conditional-checkbox input {
  width: 15px;
  height: 15px;
}

.conditional-checkbox .input-group {
  display: flex;
  align-items: center;
}

.conditional-checkbox .input-group~div {
  margin-bottom: 15px;
}

.conditional-checkbox .input-group~div label {
  margin-bottom: 5px;
}

.conditional-checkbox {
  margin-right: 20px;
}

.col a {
  color: #6d2a5f !important;
}

.data-ptag a {
  color: #6d2a5f !important;
}

.card-body p.data-ptag:first-child {
  padding-left: 0px;
}

label.label-tag-header.form-label a {
  margin-left: 10px;
  color: #fff !important;
}

label.label-tag-header.form-label {
  display: flex;
  align-items: center;
  justify-content: space-between;
}



.card-body .ag-header,
.card-body .ag-pinned-left-header,
.card-body .ag-pinned-right-header {
  z-index: 0 !important;
}

/* .card-body .ag-center-cols-viewport, .card-body .ag-center-cols-clipper, .card-body .ag-body-viewport.ag-layout-normal, .card-body .ag-root.ag-layout-normal{
  overflow: initial !important;
} */
.iSpan i.fa-close {
  margin-top: 0px;
  position: relative;
  top: -1px;
}

.PhoneInput {
  box-shadow: none !important;
  border: 1px solid #ccc !important;
  font-size: 14px !important;
  padding: 6px 12px;
  border-radius: 5px;
}

.quantitytab {
  display: inline-flex;
  width: 120px;
}

.quantitytab .form-control {
  border: 0px !important;
  text-align: center;
}

.quantity-tab .btn {
  padding: 5px 10px;
  border: 1px solid #ccc;
}

/* Chrome, Safari, Edge, Opera */
.quantitytab .form-control::-webkit-outer-spin-button,
.quantitytab .form-control::-webkit-inner-spin-button {
  -webkit-appearance: none;
  margin: 0;
}

/* Firefox */
.quantitytab .form-control[type=number] {
  -moz-appearance: textfield;
}

.reviewdate {
  font-size: 14px;
}

.starrating svg {
  color: #6d2a5f;
  font-size: 20px;
}

.box-outer {
  border: 1px solid #ccc;
  cursor: pointer;
  padding: 40px 10px;
  margin-bottom: 0px;
}

.selectbrand-outer {
  border: 1px solid #ccc;
  cursor: pointer;
  padding: 10px;
}

.Cricle-outer {
  align-items: center;
  border: 1px dashed #ccc;
  border-radius: 50%;
  color: #ccc;
  display: flex;
  height: 125px;
  justify-content: center;
  margin: 10px auto;
  width: 125px;
}

.select-itembox {
  border: 1px solid #ccc;
}

.customSelect .form-select {
  border-radius: 0px;
  border: 0px;
  height: 42px;
  box-shadow: none;
}

.checkoutpage,
.informations {
  background-size: cover;
  background-repeat: no-repeat;
  padding: 20px 0px;
  position: relative;
  background-position: center;
}

.checkoutpage::before,
.informations::before {
  content: "";
  position: absolute;
  background: #fffef5;
  top: 0px;
  bottom: 0px;
  left: 0px;
  right: 0px;
  opacity: 0.5;
}

.checkoutpage .container,
.informations .container {
  position: relative;
  z-index: 100;
}

.checkoutproductdetails {
  background: #fffef5;
  padding: 0px;
  position: relative;
  margin-bottom: 15px;
  border: 1px solid #ccc;
  border-radius: 5px;
}

.checkoutDouter {
  display: flex;
  justify-content: space-between;
  flex-wrap: wrap;
}

.checkoutleft {
  display: flex;
  align-items: flex-start;
  padding: 15px;
}

.additionalProduct {
  padding: 15px;
}

.checkimg {
  width: 170px;
  border: 1px solid #6d2a5f;
}

.checkoutDetail {
  padding-left: 15px;
}

a.removeproduct {
  position: absolute;
  right: 10px;
  bottom: 10px;
  text-decoration: none;
  color: #6d2a5f;
  z-index: 10;
  cursor: pointer;
  font-weight: 600;
}

a.removeproduct svg {
  position: relative;
  top: -2px;
}

.totals {
  display: flex;
  flex-direction: column;
  text-align: center;
  font-weight: 600;
}

.checkoutDetail a {
  text-decoration: none;
  color: #6d2a5f;
}

.checkoutDetail a h5 {
  font-weight: 700;
  font-size: 30px;
}

.checkoutDetail p {
  margin-bottom: 0px;
  font-size: 16px;
}

.checkoutDetail p span {
  font-weight: 600;
  color: #435568;
}

.totals span {
  font-weight: normal;
}

.checkoutDouter {
  position: relative;
}

.checkoutproductdetails hr {
  background: #c8c8c5;
  height: 2px;
  border: 0px;
  margin: 0px;
}

.additionalProduct h4 {
  font-size: 27px;
  font-weight: 600;
  color: #435568;
}

.additinalOuter {
  display: flex;
  flex-direction: column;
}

.additinalone {
  width: 100%;
  margin-bottom: 10px;
  display: flex;
  align-items: flex-start;

}

.addproductimg {
  min-height: 100px;
}

.addproductimg img {
  width: 100%;
}

.addproductimg {
  width: 170px;
  background: #fff;
  display: flex;
  align-items: center;
  justify-content: center;
  border: 1px solid #6d2a5f;
}

.addcontent p {
  font-size: 14px;
  margin-bottom: 5px;
  word-wrap: break-word;
}

.addcontent {
  padding: 0px 0px 0px 15px;
  width: calc(100% - 170px);
  position: relative;
}

.addcontent p span {
  pointer-events: none;
  color: #828282cc;
  display: block;
}

/* css for order Preview */
.orderaddedittable tr td,
.orderpreviewtable tr td {
  vertical-align: top;
}

.orderaddedittable,
.orderpreviewlabel {
  font-size: 15px;
  padding-left: 5px;
}

.orderaddedittable thead tr th,
.orderpreviewtable thead tr th {
  font-size: 15px;
  font-family: 'Oswald', sans-serif !important;
  background: #ccc;
}

.shippingboxestable tr td {
  vertical-align: middle;
  border: 1px solid #ccc;
}

.shippingboxestable th.shippingproductdetails {
  text-align: center;
}

.shippingboxestable {
  font-size: 15px;
  padding-left: 5px;
}

.shippingboxestable thead tr th {
  font-size: 15px;
  font-family: 'Oswald', sans-serif !important;
  background: #f8f8f8;
  border: 1px solid #ccc;
}

td.productheadings {
  padding-bottom: 0px;
}

#addEditForm table tr td div {
  word-spacing: 2px;
  margin-bottom: 5px;
}

#addEditForm table tr td div p.mb-0 {
  margin-bottom: 5px !important;
}

.couponcodelist {
  font-size: 15px;
  line-height: 24px;
}

#addEditForm table tr td,
#addEditForm table th td,
#previewForm table tr td,
#previewForm table th td {
  font-size: 15px;
  font-family: 'Oswald', sans-serif !important;
}

.orderaddedittable h5,
.orderpreviewtable h5 {
  font-weight: 700;
  margin-bottom: 0px;
}

.shippingboxestable h5 {
  font-weight: 700;
  margin-bottom: 0px;
}

.priceSummary {
  font-size: 15px;
  font-family: 'Oswald', sans-serif !important;
}

.couponcode strong {
  font-size: 15px;
  min-width: 170px;
  display: inline-block;
}

.couponcode {
  margin-bottom: 3px;
}

.couponcode,
.couponcodelist {
  margin-bottom: 3px;
}

.couponcodelist strong {
  width: 170px;
  display: inline-block;
}

.billing-heading,
td.productheadings strong {
  font-size: 14px;
}

span.table-label {
  display: inline-block;
  width: 140px;
  white-space: nowrap;
}

.tag-item {
  background: #6d2a5f;
  margin-right: 5px;
  padding: 5px 30px 5px 16px;
  border-radius: 60px;
  color: #fff;
  position: relative;
  margin-bottom: 7px;
}

.tag-item span.tag-tagStyle {
  position: absolute;
  margin: 0px;
  right: 1px;
  top: 6px;
  width: 20px;
  background: transparent;
  border: 0px;
  padding: 0px;
}

.css-xb97g8:hover,
.css-xb97g8:hover {
  background-color: #6d2a5f !important;
  color: #ffffff !important;
}

.variation-Outer .checkbox.form-check.attribute-body {
  margin: 0rem 0rem 1rem !important;
  height: initial !important;
}

.variation-Outer .checkbox.form-check.attribute-body .form-select {
  margin: 0px;
}

.variation-Outer .fitting-outer label.mainlabel.form-label {
  font-size: 14px;
}

.fitting-outer .checkbox.attribute-body.form-check {
  margin: 0px !important;
}

.fitting-outer .checkbox.attribute-body.form-check .form-select {
  margin-bottom: 1rem;
}

.VariationsModal .form-group,
.VariationsModal .quantity-tab {
  margin-bottom: 15px;
}

.VariationsModal .variation-heading {
  font-size: 15px;
  margin-bottom: 5px;
}

/* css for order Preview */
.product_customization_notes {
  max-width: 300px;
}

strong.mb-2 {
  margin-bottom: 5px !important;
  display: block;
}

.staff-info.card-header {
  display: flex;
  justify-content: space-between;
  align-items: center;
}

.ag-body-viewport.ag-layout-normal {
  overflow-y: hidden;
}

.checkouter {
  position: relative;
  padding-left: 17px;
}

.checkouter input {
  position: absolute;
  left: 0px;
}

.rightpaneltop {
  margin-left: 10px;
}

ul.nav-group-items {
  height: initial !important;
}

.viewOrderDetailstab .card {
  font-size: 15px;
}

.viewOrderDetailstab .card .label-tag-header {
  font-size: 18px;
}

.time_formatting {
  margin-left: 5px;
}

.border_bottom {
  border-bottom: 1px solid grey;
  margin-bottom: 30px;
}

.flex-outer {
  display: flex;
  align-items: flex-start;
  justify-content: space-between;
}

.text-danger.text-muted {
  color: red !important;
}

.sidebar-nav .nav-group-toggle::after {
  position: absolute;
  right: 10px;
  top: 15px;
  font-size: 41px;
  width: 20px;
}

.sidebar-nav span.nav-group-toggle {
  padding: 9px 10px 9px 10px !important;
  display: block;
  position: relative;
}

ul.nav-group-items {
  display: none;
}

.admin-panel .sidebar .nav-link svg,
.admin-panel span.nav-group-toggle svg {
  font-size: 16px;
  width: 35px;
  text-align: center;
}

.admin-panel .sidebar .nav-link,
.sidebar-nav span.nav-group-toggle {
  font-size: 14px;
  padding: 12px 10px !important;
}

.admin-panel .sidebar .nav-item.active>.nav-link {
  background: #754069 !important;
}

.sidebar-nav span.nav-group-toggle:hover {
  background: #6d2a5f;
}

.admin-panel .sidebar li.nav-item.active ul.nav-group-items {
  display: block;
}

li.nav-item.active {
  background: rgb(117 64 105);
}

li.nav-item.active span.nav-group-toggle {
  background: #754069;
}

li.nav-item.active ul.nav-group-items li.nav-item.active a.nav-link {
  background: #6d2a5f !important;
}

.col-bottom {
  padding-bottom: 10px;
  margin-bottom: 10px;
  border-bottom: 1px solid #ccc;
}

.col-bottom.row:last-child {
  border: 0px;
}

.strikeit {
  text-decoration: line-through;
}

table.table.table-striped.border thead tr th {
  background: #ccc;
}

.table-striped>tbody>tr:nth-of-type(odd)>* {
  --cui-table-accent-bg: rgb(255 255 255 / 35%) !important;
}

.star_checked {
  color: orange;
}

input#image {
  margin-bottom: 10px;
}

td.varition-btns {
  display: flex;
}

.notes-card {
  border: 1px solid #ccc;
  border-radius: 5px;
  padding: 10px 0px 0px;
}

.notes-card .card {
  border: 0px;
  margin-bottom: 0px;
}

.notes-card .card .card-body {
  padding-bottom: 0px;
  padding-top: 0px;
}

.notes-card .card .card-body p+p {
  margin-bottom: 0px;
  font-size: 11px;
  position: relative;
  top: -15px;
}

.notes-card .card .card-body p+p .time_formatting {
  margin: 0px;
}

.reset-button-tab {
  position: absolute;
  right: 10px;
  flex: auto;
  width: auto;
  padding: 0px;
  top: 10px;
}

.bg-light.setreset {
  position: relative;
}

@media(max-width:991.98px) {
  .tabletopsec.myorder {
    flex-wrap: wrap;
  }

  .tabletopsec.myorder .rightpaneltop {
    margin-left: 0px;
  }

  button.btn.togglebars {
    left: 215px;
  }

  button.sidebar-toggler.d-lg-none {
    display: none;
  }

  .sidebar {
    max-width: 200px !important;
  }

  span.navbar-brand {
    width: 200px;
  }



  span.navbar-brand img {
    width: 100% !important;
  }

  .checkouter.mb-3 {
    width: 150px;
  }

  .viewOrderDetailstab .card {
    font-size: 16px;
  }

  .tag-list {
    max-height: 145px;
    overflow: auto;
  }

}

@media(max-width:575.98px) {
  .tabletopsec {
    flex-wrap: wrap;
  }

  .admin-panel .add-button-div a+button.btn.btn-primary.btn-sm {
    width: auto;
  }

  .ag-theme-alpine .ag-paging-panel>* {
    margin: 0 0px !important;
  }

  .admin-panel .add-button-div a.addNewElementClass {
    display: block;
    margin-bottom: 0px;
  }

  .admin-panel .add-button-div a.addNewElementClass button.btn.btn-primary.btn-sm {
    width: 100%;
    margin: 0px !important;
  }

  .rightpaneltop {
    margin: 5px 0px 0px 0px;
    width: 100%;
  }

  .admin-panel .add-button-div {
    width: 100% !important;
  }

  .admin-panel .add-button-div button.btn.btn-primary.btn-sm {
    width: 100%;
  }
}

.admin-panel .userTableList button.btn-link,
button.btn-link {
  background: transparent;
  color: #999999;
  border: 0px;
  text-decoration: none;
}

.admin-panel .userTableList button.btn-link:hover {
  color: #6d2a5f;
}

.admin-panel .userTableList button.active {
  color: #6d2a5f;
}

.nav-group-toggle .badge {
  --cui-badge-font-size: 0.50em !important;
}

.sidebar-nav .nav-item .badge.bg-danger {
  margin: 0px;
  display: inline-block;
  position: absolute;
  right: 40px;
  top: 20px;
}

.sidebar-nav .nav-group-items .nav-link {
  position: relative;
}

.sidebar-nav .nav-group-items .badge.bg-danger {
  right: 10px;
  top: 13px;
}

.address.billingADrs {
  padding-top: 18px;
}

.orderaddedittable .bg-light td:last-child table tr td {
  min-width: 70px;
}

.shippingboxestable .bg-light td:last-child table tr td {
  min-width: 70px;
}

.form-control[type="file"] {
  margin-bottom: 10px;
}

.product-infromation td {
  padding-top: 0px;
}

.card-body .input-group-text {
  width: 52px;
}

.viewpass {
  float: right;
  margin-right: 10px;
  margin-top: -32px;
  position: relative;
  font-size: medium;
  z-index: 2;
}

.rightpaneltop input#search-text-box {
  width: 160px;
}

.refund-modal.modal-footer {
  justify-content: space-between;
  flex-wrap: nowrap;
}

.refund-modal.modal-footer button.btn.btn-danger.btn-sm {
  margin-left: 10px;
}

.submit-close {
  width: 100%;
  display: flex;
  justify-content: flex-end;
}

.refund-modaL .card-header {
  display: flex;
  justify-content: space-between;
  align-items: center;
}

.refund-modaL .card-header a {
  margin-left: auto;
}

.ag-theme-alpine .ag-layout-auto-height .ag-center-cols-clipper,
.ag-theme-alpine .ag-layout-auto-height .ag-center-cols-container,
.ag-theme-alpine .ag-layout-print .ag-center-cols-clipper,
.ag-theme-alpine .ag-layout-print .ag-center-cols-container {
  min-height: initial !important;
}

button.btn.btn-info.btn-sm svg path {
  fill: #74005f;
}

button.btn.btn-info.btn-sm svg {
  width: 12px;
}

button.btn.btn-info.btn-sm:hover svg path {
  fill: #fff;
}

.admin-panel .userTableList button.btn.btn-info.btn-sm,
.admin-panel .editProdBtn button.btn.btn-info.btn-sm {
  background: transparent !important;
  color: #6d2a5f !important;
}

.admin-panel .userTableList button.btn.btn-info.btn-sm:hover,
.admin-panel .editProdBtn button.btn.btn-info.btn-sm:hover {
  background: #6d2a5f !important;
  color: #fff !important;
}

/* Loader 1 */
.loader-1 {
  height: 22px;
  width: 22px;
  -webkit-animation: loader-1-1 4.8s linear infinite;
  animation: loader-1-1 4.8s linear infinite;
}

@-webkit-keyframes loader-1-1 {
  0% {
    -webkit-transform: rotate(0deg);
  }

  100% {
    -webkit-transform: rotate(360deg);
  }
}

@keyframes loader-1-1 {
  0% {
    transform: rotate(0deg);
  }

  100% {
    transform: rotate(360deg);
  }
}

.loader-1 span {
  display: block;
  position: absolute;
  top: 0;
  left: 0;
  bottom: 0;
  right: 0;
  margin: auto;
  height: 22px;
  width: 22px;
  clip: rect(0, 22px, 22px, 10px);
  -webkit-animation: loader-1-2 1.2s linear infinite;
  animation: loader-1-2 1.2s linear infinite;
}

@-webkit-keyframes loader-1-2 {
  0% {
    -webkit-transform: rotate(0deg);
  }

  100% {
    -webkit-transform: rotate(220deg);
  }
}

@keyframes loader-1-2 {
  0% {
    transform: rotate(0deg);
  }

  100% {
    transform: rotate(220deg);
  }
}

.loader-1 span::after {
  content: "";
  position: absolute;
  top: 0;
  left: 0;
  bottom: 0;
  right: 0;
  margin: auto;
  height: 22px;
  width: 22px;
  clip: rect(0, 22px, 22px, 10px);
  border: 3px solid #FFF;
  border-radius: 50%;
  -webkit-animation: loader-1-3 1.2s cubic-bezier(0.770, 0.000, 0.175, 1.000) infinite;
  animation: loader-1-3 1.2s cubic-bezier(0.770, 0.000, 0.175, 1.000) infinite;
}

@-webkit-keyframes loader-1-3 {
  0% {
    -webkit-transform: rotate(-140deg);
  }

  50% {
    -webkit-transform: rotate(-160deg);
  }

  100% {
    -webkit-transform: rotate(140deg);
  }
}

@keyframes loader-1-3 {
  0% {
    transform: rotate(-140deg);
  }

  50% {
    transform: rotate(-160deg);
  }

  100% {
    transform: rotate(140deg);
  }
}

.repet-box {
  display: flex;
  align-items: end;
  padding-left: 18px;
}

.qty-input {
  width: 75px;
}

.productName-label {
  width: calc(50% - 100px);
  padding-left: 15px;
  display: flex;
  align-items: center;
  padding-bottom: 7px;
  font-size: 15px;
}

.productName-label input {
  width: 20px;
  height: 20px;
  margin-right: 8px;
}

.qty-input label {
  font-size: 15px;
  margin-bottom: 3px;
}

.returnModal-inner h5 {
  font-size: 18px;
}

.returnModal-inner {
  border-bottom: 1px solid #ccc;
  padding-bottom: 20px;
  margin-bottom: 20px;
}

.modal-body .row:last-child .returnModal-inner {
  border: 0px;
}

.disabled-link {
  background: inherit !important;
  /* Keeps the link color same as regular text */
  text-decoration: none;
  /* Removes underline */
  cursor: not-allowed;
  /* Changes the cursor to not-allowed, indicating it's not clickable */
  pointer-events: none;
}

a .loading img {
  width: 17px !important;
  margin: 0px;
}

.action.loading.btn.btn-info {
  background: transparent !important;
}

.initiate_return.modal-body {
  max-height: 900px;
  overflow: auto;
}

@media(min-width: 768px) {
  span.blackOverlay {
    background: #000;
    position: absolute;
    top: 0px;
    bottom: 0px;
    left: 0px;
    right: 0px;
    opacity: 0.3;
  }

  nav.sidebar {
    position: relative;
  }
}

.image-container {
  position: relative;
  max-width: 80vw;
  max-height: 80vh;
}

.gallery-image-container {
  width: 130px;
  height: 70px;
  z-index: 100px;
}

.gallery-image {
  width: 130px;
  height: 70px;
}

.modal {
  position: fixed;
  width: 100%;
  height: 100%;
  overflow: auto;
  background-color: rgba(0, 0, 0, 0.7);
  /* Semi-transparent background */
  display: none;
  justify-content: center;
  align-items: center;
}

.modal-image {
  max-width: 80vw;
  /* Adjust maximum width as needed */
  max-height: 80vh;
  /* Adjust maximum height as needed */
  width: 100%;
  height: 450px;
  /* transform: translate(50%, 50%); */
}

/* .gallery-image:hover+.modal {
  display: block;
} */

.gallery-image-container:hover+.modal {
  display: flex;
}

.image-container .modal {
  z-index: 10000;
}
.per-page-select .css-1s2u09g-control {
  min-height: 35px;
}

.per-page-select .css-1s2u09g-control .css-tlfecz-indicatorContainer {
  padding: 5px;
}

.per-page-select span.css-1okebmr-indicatorSeparator {
  display: none;
}
.order-per-Pages .form-label {
  margin-bottom: 1px;
}
.per-page-select .css-1pahdxg-control:hover {
  min-height: 35px;
}
.loading.btn.btn-info {
  width: 31.26px;
  height: 31.72px;
  padding: 2px;
  text-align: center;
  background: #6d2a5f !important;
  border: 1px solid #6d2a5f !important;
  margin-bottom: 10px;
  margin-right: 10px;
}

.loading.btn.btn-info img {
  width: 23px;
  height: 22px;
}
/* .loading.btn.btn-info {
  position: absolute;
  left: 0px;
  right: 0px;
  top: 0px;
  padding: 0px;
  bottom: 0px;
  background: #6d2a5f !important;
  border-color: #6d2a5f !important;
}

button.loder-btn-tab {
  position: relative;
}

.loading.btn.btn-info img {
  width: 25px;
  height: 22px;
} */