// Styles

// CoreUI Icons Set
//@import "~@coreui/icons/css/coreui-icons.css";
@import "~@coreui/icons/css/all.min.css";

//commented by vimal-25-04-2022
//@import "~flag-icon-css/css/flag-icon.min.css";

@import "~@coreui/icons/css/flag.min.css";


//commented by vimal-25-04-2022
//@import "~font-awesome/css/font-awesome.min.css";



@import "~simple-line-icons/css/simple-line-icons.css";

// 
//@import './scss/style.scss';

// If you want to override variables do it here
// @import "variables";


@import "~@coreui/coreui/scss/coreui.scss";

// Temp fix for reactstrap
//file not available -- commented by vimal-25-04-2022
//@import "~@coreui/coreui/scss/_dropdown-menu-right.scss";
//@import "~@coreui/coreui/scss/_dropdown.scss";


// // If you want to add something do it here
// @import "admin";
@import "custom";
// @import "front-custom";

@import 'font-awesome/css/font-awesome.min.css';


