table {
  font-family: arial, sans-serif;
  border-collapse: collapse;
  width: 100%;
}

td,
th {
  border: 1px solid #dddddd;
  text-align: left;
  padding: 8px;
}

/* tr:nth-child(even) {
    background-color: #dddddd;
  } */

.order-margin {
  /* margin-left: 290px; */
  margin-top: 10px;
}

.add-product {
  margin-left: -18rem;
  width: 173px;
  margin-bottom: 15px;
  margin-top: -8px;
}

.coupon-box {
  width: 12rem !important;
  margin-top: -37px;
  margin-left: 17rem;
}

.coupon-btn {
  margin-left: 85px;
  margin-bottom: 10px;
}

.cancle-btn {
  margin: -55px 0px 0px 58rem;
}

.recalculate-btn {
  margin: -55px 0px 0px 48rem;
}

.mt-20 {
  margin-top: 20px;
}

.padding-left {
  padding-left: 3px;
}

.variation-heading {
  font-size: 20px;
  font-weight: bold;
}

.update-btn {
  margin-left: 54rem !important;
}

.paid-amount {
  margin-top: -34px;
  margin-left: 7rem;
  width: 77px !important;
}

.ml-86 {
  margin-left: 86%;
}